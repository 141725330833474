.pia {
  &-navigationBlock {
    position: relative;
    background-color: $pia-blue;
    margin-top: 4px;
    &-structures {
      background-color: $pia-green;
    }
    &-knowledges {
      background-color: $pia-red;
    }
    .container {
      position: relative;
    }
    &-list {
      display: inline-block;
      margin: 0;
      padding: 0;
      &:last-child {
        float: right;
      }
      > li {
        display: inline-block;
        vertical-align: top;
        height: 28px;
        > a {
          display: block;
          padding: 4px 20px;
          color: #fff;
          font-weight: 500;
          font-size: 1.38rem;
          &:focus,
          &:hover,
          &:active {
            background-color: $pia-green;
          }
        }
        a {
          text-decoration: none;
        }
      }

      .pia-navigationBlock-withsub {
        > a {
          cursor: context-menu;
        }
        > ul {
          display: none;
          background-color: #fff;
          padding: 0 10px;
          margin: 0;
          position: absolute;
          top: 28px;
          right: -1px;
          min-width: 120px;
          color: $pia-blue;
          list-style-type: none;
          z-index: 2000;
          border: 1px solid $pia-gray;
          border-top: 0;
          li {
            text-align: left;
            color: #fff;
            cursor: pointer;
            border-bottom: 1px solid $pia-gray;
            &:last-child {
              border-bottom: 0px;
            }
            a {
              padding: 10px 0 !important;
              color: $pia-light-black !important;
              font-weight: 400 !important;
              color: $pia-blue;
              padding: 0 8px;
            }
          }
        }
        &:hover {
          > a {
            color: $pia-blue;
          }
          > a,
          > ul {
            background-color: #fff;
          }
          > ul {
            display: block;
            background-color: #fff;
            > li {
              background-color: #fff;
              a {
                display: block;
                &:focus,
                &:hover,
                &:active {
                  font-weight: 500 !important;
                }
              }
            }
          }
        }
      }

      .pia-navigationBlock-noSub {
        border-right: 1px solid #fff;
      }

      .pia-navigationBlock-dropdown {
        position: relative;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        > a {
          padding: 4px 14px !important;
          cursor: context-menu;
          text-align: center;
          .pia-icon-scroll {
            margin-top: 5px;
            margin-left: 8px;
            float: right;
          }
          &:focus,
          &:hover,
          &:active {
            color: #fff !important;
            background-color: $pia-green;
            & + form {
              display: block;
            }
            .pia-icon-scroll {
              background-position: -367px -39px;
            }
          }
        }
        &:focus,
        &:hover,
        &:active {
          > a {
            color: #fff !important;
            background-color: $pia-green;
            .pia-icon-scroll {
              background-position: -367px -39px;
            }
          }
          > form {
            display: block;
          }
        }
      }
      .pia-navigationBlock-knowledges,
      .pia-navigationBlock-pias,
      .pia-navigationBlock-structures {
        & > a {
          text-transform: uppercase !important;
        }
      }

      .pia-navigationBlock-pias {
        > ul {
          right: initial;
          left: -1px;
        }
      }

      .pia-navigationBlock-company {
        form {
          display: none;
          border: 1px solid $pia-gray;
          border-top: none;
          position: absolute;
          width: 185px;
          top: 28px;
          right: -1px;
          color: $pia-light-black;
          padding: 0 10px;
          z-index: 999999;
          background-color: #fff;
          > fieldset {
            border: none;
            margin: 0;
            padding: 0;
            a {
              cursor: pointer;
              &:hover {
                font-weight: 500 !important;
              }
            }
            > div:hover {
              label {
                font-weight: 500 !important;
              }
            }
          }
          div {
            border-top: 1px solid $pia-gray !important;
            padding: 10px 0 !important;
            &:first-of-type {
              border-top: none !important;
            }

            /* input, label {
              display: inline-block;
              vertical-align: top;
            }
            input {
              width: 13px;
              margin-top: 3px;
            }
            label {
              padding: 0;
              margin: 0;
              margin-left: 4px;
              width: 88%;
              font-size: 1.2rem;
              line-height: 1.4;
              cursor: pointer;
              span {
                font-style: italic;
              }
            } */
            &:last-child {
              border: 0;
              padding: 0;
              margin-bottom: 0;
            }
            /* Custom checkboxes styles */
            input {
              position: absolute;
              left: -9999px;
            }
            label {
              padding-left: 20px;
              cursor: pointer;
              display: block;
              position: relative;
              &:focus,
              &:hover {
                &:before {
                  border: 1px solid #000;
                }
              }
            }
            label::before {
              content: "";
              display: block;
              position: absolute;
              top: 3px;
              left: 0;
              width: 16px;
              height: 16px;
              border: 1px solid $pia-gray-dark;
              transition: all 0.3s;
            }
            input:checked + label::before {
              background-color: $pia-green-dark;
              box-shadow: inset 0px 0px 0px 1px #fff;
            }

            > a {
              cursor: pointer;
              &:hover {
                font-weight: 500 !important;
              }
            }
          }
          a {
            //padding: 10px 0 !important;
            color: $pia-light-black !important;
            font-weight: 400 !important;
            display: block;
          }
        }
      }

      .pia-navigationBlock-help {
        border-left: 0 !important;
        border-right: 0 !important;
        > ul {
          min-width: 140px;
        }
        button {
          padding: 10px 0 !important;
          cursor: pointer;
          color: $pia-light-black;
          font-weight: 400 !important;
          &:hover {
            font-weight: 500 !important;
          }
        }
      }

      .pia-navigationBlock-languages {
        min-width: 60px;
        color: #fff;
        position: relative;
        border-right: 1px solid #fff;
        > a {
          min-width: 60px;
          padding: 3px 14px !important;
        }
        .fa-globe {
          font-size: 1.8rem;
          margin-top: 2px;
        }
        > ul {
          min-width: 200px;
          padding-bottom: 10px;
          li {
            border-bottom: 0;
            cursor: default;
            a {
              cursor: pointer;
              padding: 0 5px !important;
            }
            &.is_active {
              background-color: $pia-blue !important;
              a {
                color: $pia-white !important;
                font-weight: 500 !important;
              }
            }
          }
          .officialTranslation,
          .cnilTranslations,
          .dpaTranslations,
          .communityTranslations {
            font-weight: 600;
            color: $pia-light-black;
            list-style-type: none;
            padding: 5px 8px;
          }
          .cnilTranslations,
          .dpaTranslations,
          .communityTranslations {
            border-top: 1px solid $pia-gray;
            padding-bottom: 3px;
          }
        }
      }
    }
  }
}
