@media print {
  .hide-for-print {
    display: none !important;
  }
  .pia-actionPlanGraphBlock,
  .pia-mainContentForPrint,
  .pia-entryContentBlock {
    width: 100% !important;
    max-width: 100% !important;
    flex: inherit !important;
  }
  .pia-summaryRisksOverviewBlock,
  .pia-summaryRisksCartographyBlock {
    > div {
      width: 100% !important;
    }
  }
}
