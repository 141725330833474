@import '../../assets/stylesheets/variables';
.btn {
  cursor: pointer;
  text-align: center;
  background: none;
  &-default {
    border: 1px solid transparent;
    padding: 9px 20px;
    border-radius: 20px;
    font-size: 1.35rem;
    color: white;
    &:hover, &:focus {
      color: white;
    }
    &:active {
      background-color: white;
    }
  }
  &.hide {
    @extend .btn-default;
  }

  &-inactive, &[disabled] {
    @extend .btn-default;
    color: $pia-gray;
    border-color: $pia-gray;
    cursor: not-allowed;
    background-color: transparent;
    &:focus, &:hover {
      color: $pia-gray;
      background-color: transparent;
    }
  }

  &-white {
    @extend .btn-default;
    color: $pia-green;
    background-color: white;
    &:focus, &:hover, &.btn-active {
      color: $pia-green;
    }
  }

  &-search {
    @extend .btn-default;
    border-radius: 0;
    width: 100%;
    text-align: left;
    background-color: #fff;
    color: #000;
    border-color: #aaa9a9;
    cursor: text;
    &::placeholder {
      color: #aaa9a9;
    }
  }

  &-green {
    @extend .btn-default;
    color: $pia-green;
    border-color: $pia-green;
    &:focus, &:hover, &.btn-active, &.btn-active[disabled] {
      background-color: $pia-green;
      color: white;
    }
  }

  &-blue {
    @extend .btn-default;
    color: $pia-blue;
    border-color: $pia-blue;
    &:focus, &:hover, &.btn-active, &.btn-active[disabled] {
      background-color: $pia-blue;
      color: white;
    }
  }

  &-red {
    @extend .btn-default;
    color: $pia-red;
    border-color: $pia-red;
    &:focus, &:hover, &.btn-active, &.btn-active[disabled] {
      background-color: $pia-red;
      color: white;
    }
  }

  &-pending-validation, &-pending-validation[disabled] {
    font-size: 1.3rem;
    cursor: not-allowed;
    border: 1px solid $pia-blue;
    color: $pia-blue;
    font-weight: 600;
    .fa {
      font-size: 1.8rem;
      margin-right: 8px;
      vertical-align: -3px;
    }
    &:focus, &:hover, &:active {
      color: $pia-blue;
    }
  }

  &-addMeasure {
    border-radius: 0;
    width: 100%;
    box-shadow: 1px 1px 15px 1px #d2d2d2;
    margin-bottom: 10px;
  }
}
